import React, { useState } from "react";
import { ProCard } from "@ant-design/pro-components";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { JWS, JWK } from "node-jose";
import jwt_decode from "jwt-decode";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "../stores";
import moment from "moment";
import FormQ from "./forms/FormQ";

const TradingAPI = () => {
  const [myHost, setMyHost] = useState("");
  const [form] = Form.useForm();
  // const C2C = useStore((state) => state.C2C);
  // const setC2C = useStore((state) => state.setC2C);

  const token = localStorage.getItem("C2C");

  if ((token && moment().isAfter(moment.unix(jwt_decode(token).exp))) || !token) {
    localStorage.removeItem("C2C");
  }

  const getTokenHandler = () => {
    const param = form.getFieldsValue();

    const payload = JSON.stringify({
      group: param["Group"],
      entity: param["Entity"],
      iss: "orbis_api_web_test",
      jti: uuidv4(),
      iat: Math.floor(Date.now() / 1000),
      exp: Math.floor((Date.now() + 60 * 1000) / 1000),
    });

    // Get JWS
    (async () => {
      // Create key
      const key = await JWK.asKey({
        kty: "oct",
        kid: param["Secret"],
        k: Buffer.from(param["Secret"]).toString("base64"),
      });

      // Create jws
      const jws = await JWS.createSign(
        { format: "compact" },
        {
          key: key,
          header: {
            alg: "HS256",
          },
        }
      )
        .update(payload)
        .final();

      // Get token
      if (!token || moment().isAfter(moment.unix(jwt_decode(token).exp))) {
        try {
          // API Proxy query
          const { data } = await axios.get(`${process.env.REACT_APP_API_PROXY_HOST}/c2c/jws.action?jws=${jws}`, {
            headers: { "Target-Endpoint": `${param["Host"]}` },
          });

          if (data.success === true) {
            localStorage.setItem("C2C", data.token);
            form.setFieldValue("Token", data.token);
            form.setFieldValue("Parse", JSON.stringify(jwt_decode(data.token), null, 4));
          } else {
            form.setFieldValue("Token", data.message);
          }
        } catch (err) {
          form.setFieldValue("Token", err);
          console.log(err);
        }
      }
    })();
  };

  return (
    <ProCard gutter={[8, 8]} wrap style={{ marginBlockStart: 10, marginBlockEnd: 90 }}>
      <ProCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }} title="Token Query" headerBordered>
        <Form
          form={form}
          labelCol={{
            xs: { span: 4 },
            sm: { span: 4 },
            md: { span: 4 },
            lg: { span: 4 },
            xl: { span: 4 },
            xxl: { span: 3 },
          }}
          wrapperCol={{
            xs: { span: 20 },
            sm: { span: 20 },
            md: { span: 16 },
            lg: { span: 16 },
            xl: { span: 20 },
            xxl: { span: 20 },
          }}
          layout="horizontal"
          initialValues={{
            // Host: "https://wrh.orbisfn.net",
            // Secret: "&F)J@NcRfUjXn2r5u8x/A%D*G-KaPdSg",
            // Group: "UAT-2",
            // Entity: "viewtrade",
            Token: token ? token : "",
            Parse: token ? JSON.stringify(jwt_decode(token), null, 4) : "",
          }}
        >
          <Form.Item
            name="Host"
            label="Host"
            rules={[
              {
                required: true,
                message: "Please input Host",
              },
            ]}
          >
            <Input value={myHost} onChange={(e) => setMyHost(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="Secret"
            label="Secret"
            rules={[
              {
                required: true,
                message: "Please input Secret",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Entity"
            label="Entity"
            rules={[
              {
                required: true,
                message: "Please input Entity",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Group"
            label="Group"
            rules={[
              {
                required: true,
                message: "Please input Group",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { offset: 0 },
              sm: { offset: 4 },
              md: { offset: 4 },
              lg: { offset: 4 },
              xl: { offset: 4 },
              xxl: { offset: 3 },
            }}
          >
            <Button type="primary" htmlType="submit" onClick={getTokenHandler}>
              Get Token
            </Button>
          </Form.Item>

          <Form.Item name="Token" label="Token">
            <Input.TextArea
              rows={7}
              onChange={(e) => {
                localStorage.setItem("C2C", e.target.value);
              }}
              style={{ resize: "none", overflowX: "hidden" }}
            />
          </Form.Item>
          <Form.Item name="Parse" label="Parse">
            <Input.TextArea bordered={false} rows={8} wrap="off" style={{ resize: "none", overflowX: "hidden" }} />
          </Form.Item>
        </Form>
        {/* ==== Form end ====*/}
      </ProCard>

      <ProCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }} title="Data Query" headerBordered>
        {/* <FormT scheme="C2C" /> */}
        <FormQ host={myHost} scheme="C2C" />
      </ProCard>
    </ProCard>
  );
};

export default TradingAPI;
