import React, { useEffect } from "react";
import { Form, Button, Collapse, Input, Select, Spin } from "antd";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
// import { useStore } from "../../stores";

const OrbisAPIQuery = async ({ method, host, proxy_host, scheme, endpoint, body }) => {
  const token = localStorage.getItem(scheme);
  const res = await axios({
    method: method,
    url: proxy_host + endpoint,
    headers: {
      "Target-Endpoint": host,
      Authorization: `${scheme} ${token}`,
    },
    data: body ? JSON.parse(body) : "",
  });
  return res;
};

const FormQ = ({ host, scheme }) => {
  const API_PROXY_HOST = process.env.REACT_APP_API_PROXY_HOST;
  const [formQ] = Form.useForm();

  const apiQuery = useQuery({
    queryKey: ["apiQuery"],
    queryFn: () =>
      OrbisAPIQuery({
        method: formQ.getFieldValue("Method"),
        host: host,
        proxy_host: API_PROXY_HOST,
        scheme: scheme,
        endpoint: formQ.getFieldValue("Endpoint"),
        body: formQ.getFieldValue("Body"),
      }),
    enabled: false,
  });

  // if (apiQuery.isSuccess) {
  //   formQ.setFieldValue("Result", apiQuery.data);
  // } else {
  //   formQ.setFieldValue("Result", "");
  // }

  useEffect(() => {
    formQ.setFieldValue("Result", JSON.stringify(apiQuery?.data?.data, null, 4));
  }, [apiQuery.data]);

  const dataQueryHandler = () => {
    // ==== Use react-query ====
    apiQuery.refetch();

    // ==== Previous method ====
    // const token = localStorage.getItem(scheme);
    // (async () => {
    //   try {
    //     const { data } = await axios({
    //       method: formQ.getFieldValue("Method"),
    //       url: API_PROXY_HOST + formQ.getFieldValue("Endpoint"),
    //       headers: {
    //         "Target-Endpoint": host,
    //         Authorization: `${scheme} ${token}`,
    //       },
    //       data: formQ.getFieldValue("Body") ? JSON.parse(formQ.getFieldValue("Body")) : "",
    //     });
    //     formQ.setFieldValue("Result", JSON.stringify(data, null, 4));
    //   } catch (error) {
    //     formQ.setFieldValue("Result", error);
    //   }
    // })();
  };

  return (
    <Form
      form={formQ}
      layout="vertical"
      initialValues={{
        Method: "GET",
        // Endpoint: "/api/user/account?account=TRYK0001",
      }}
    >
      <Form.Item>
        <Input.Group compact style={{ display: "flex", flexWrap: "nowrap" }}>
          <Form.Item name="Method" noStyle>
            <Select
              style={{ width: "130px" }}
              // onChange={(value) => setQueryMethod(value)}
              options={[
                { value: "GET", label: "GET" },
                { value: "POST", label: "POST" },
                { value: "PUT", label: "PUT" },
                { value: "PATCH", label: "PATCH" },
                { value: "DELETE", label: "DELETE" },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item name="Endpoint" noStyle>
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="Send" noStyle>
            <Button type="primary" style={{ width: "100px" }} onClick={dataQueryHandler}>
              Send
            </Button>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Collapse bordered={false} defaultActiveKey={["Result"]}>
        <Collapse.Panel header="Body" key="Body">
          <Form.Item name="Body">
            <Input.TextArea
              rows={6}
              // value={queryData}
              // onChange={(e) => setQueryData(e.target.value)}
              style={{ resize: "none", overflowX: "hidden" }}
            />
          </Form.Item>
        </Collapse.Panel>
        <Collapse.Panel header="Result" key="Result">
          <Spin spinning={apiQuery.isFetching}>
            <Form.Item name="Result">
              <Input.TextArea rows={20} style={{ resize: "none", overflowX: "hidden" }} />
            </Form.Item>
          </Spin>
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
};

export default FormQ;
