import { create } from "zustand";

export const useStore = create((set) => ({
  Bearer: "",
  C2C: "",
  setBearer: (Bearer) =>
    set((state) => ({
      ...state,
      Bearer,
    })),
  setC2C: (C2C) =>
    set((state) => ({
      ...state,
      C2C,
    })),
}));
