import { PageContainer } from "@ant-design/pro-components";
import { Row, Col } from "antd";
import { TradingAPI, QuoteAPI, WatchmenAPI } from "./components/";

function App() {
  return (
    <div
      style={{
        height: "100vh",
        background: "#F5F7FA",
      }}
    >
      <Row>
        <Col
          xs={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          lg={{ offset: 0, span: 24 }}
          xl={{ offset: 3, span: 18 }}
          xxl={{ offset: 3, span: 18 }}
        >
          <PageContainer
            ghost
            header={{
              title: "Orbis API Auth",
              breadcrumb: {},
            }}
            tabList={[
              {
                tab: "Trading API",
                key: "1",
                children: <TradingAPI />,
              },
              {
                tab: "Watchmen API",
                key: "2",
                children: <WatchmenAPI />,
              },
              // {
              //   tab: "Quote API",
              //   key: "3",
              //   children: <QuoteAPI />,
              // },
            ]}
            // footer={[]}
          ></PageContainer>
        </Col>
      </Row>
    </div>
  );
}

export default App;
