import React, { useState } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import { ProCard } from "@ant-design/pro-components";
import { UploadOutlined } from "@ant-design/icons";
import { JWE, JWK } from "node-jose";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import FormQ from "./forms/FormQ";

const WatchmenAPI = () => {
  const [myHost, setMyHost] = useState("");
  const [pubKey, setPubKey] = useState("");
  const [form] = Form.useForm();

  const token = localStorage.getItem("Bearer");
  if (token && moment().isAfter(moment.unix(jwt_decode(token).exp))) {
    localStorage.removeItem("Bearer");
  }

  const getTokenHandler = () => {
    const param = form.getFieldsValue();
    const keystore = JWK.createKeyStore();
    const payload = JSON.stringify({
      iss: "watchman API",
      iat: Math.floor(Date.now() / 1000),
      exp: Math.floor((Date.now() + 60 * 1000) / 1000),
      nbf: Math.floor(Date.now() / 1000),
      jti: uuidv4(),
    });

    (async () => {
      const key = await keystore.add(pubKey, "pem", { kid: param["AccessKey"] });

      //// Create JWE token
      var jwetoken = await JWE.createEncrypt(
        {
          format: "compact",
          fields: {
            alg: "RSA-OAEP-256",
            kid: param["AccessKey"],
            enc: "A128GCM",
          },
        },
        key
      )
        .update(payload)
        .final();

      if (!token || moment().isAfter(moment.unix(jwt_decode(token).exp))) {
        try {
          //// API Proxy query
          const res = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_PROXY_HOST}/v1/oms/token`,
            data: {
              jwe: jwetoken,
            },
            headers: {
              "Target-Endpoint": `${param["Host"]}`,
              "Content-type": "application/json",
            },
          });
          localStorage.setItem("Bearer", res.data.content);
          form.setFieldValue("Token", res.data.content);
          form.setFieldValue("Parse", JSON.stringify(jwt_decode(res.data.content), null, 4));
        } catch (error) {
          console.log(error);
        }
      }
    })();
  };

  const uploadHandler = (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      setPubKey(e.target.result);
      message.success(`Load public key success`);
    };
    reader.onerror = (e) => {
      setPubKey("");
      message.error(`Load public key fail`);
    };
    return false;
  };

  return (
    <ProCard gutter={[8, 8]} wrap style={{ marginBlockStart: 10, marginBlockEnd: 90 }}>
      <ProCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }} title="Token Query" headerBordered>
        <Form
          form={form}
          labelCol={{
            xs: { span: 4 },
            sm: { span: 4 },
            md: { span: 4 },
            lg: { span: 4 },
            xl: { span: 6 },
            xxl: { span: 5 },
          }}
          wrapperCol={{
            xs: { span: 20 },
            sm: { span: 20 },
            md: { span: 16 },
            lg: { span: 16 },
            xl: { span: 18 },
            xxl: { span: 19 },
          }}
          layout="horizontal"
          initialValues={{
            // Host: "https://bo.orbisfn.com/ccls_demo/api",
            // AccessKey: "498fe345-5a7d-4378-9009-bd0acfd5c401",
            Token: token ? token : "",
            Parse: token ? JSON.stringify(jwt_decode(token), null, 4) : "",
          }}
        >
          <Form.Item
            name="Host"
            label="Host"
            rules={[
              {
                required: true,
                message: "Please input Host",
              },
            ]}
          >
            <Input value={myHost} onChange={(e) => setMyHost(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="AccessKey"
            label="Access Key"
            rules={[
              {
                required: true,
                message: "Please input AccessKey",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="PublicKey" label="Public Key">
            <Upload accept=".pem" showUploadList={false} beforeUpload={uploadHandler}>
              <Button icon={<UploadOutlined />}>Load Public Key</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              xs: { offset: 0 },
              sm: { offset: 4 },
              md: { offset: 4 },
              lg: { offset: 4 },
              xl: { offset: 4 },
              xxl: { offset: 4 },
            }}
          >
            <Button type="primary" htmlType="submit" onClick={getTokenHandler}>
              Get Token
            </Button>
          </Form.Item>
          <Form.Item name="Token" label="Token">
            <Input.TextArea
              rows={7}
              onChange={(e) => {
                localStorage.setItem("Bearer", e.target.value);
              }}
              style={{ resize: "none", overflowX: "hidden" }}
            />
          </Form.Item>
          <Form.Item name="Parse" label="Parse">
            <Input.TextArea bordered={false} rows={8} wrap="off" style={{ resize: "none", overflowX: "hidden" }} />
          </Form.Item>
        </Form>
        {/* ==== Form end ====*/}
      </ProCard>

      <ProCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }} title="Data Query" headerBordered>
        <FormQ host={myHost} scheme="Bearer" />
      </ProCard>
    </ProCard>
  );
};

export default WatchmenAPI;
